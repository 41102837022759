@searchButtonColor: @secondaryColor;

/** Allows you to display the description on 3 lines **/
#dynamic-front-page-content > div.navigation-content > div > div.ui.divided.link.relaxed.items > div > div > div.description{
	display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

/** keep the header box-shadow on the navigation tabs **/
body > header {
	z-index: 1;
}

/** enlarges the ulysseus logo **/
.rdm-logo {
	display: block;
	height: 100%;
	transform: scale(1.5);
}

.outer-navbar,
.footer-bottom,
.footer-top {
	background: radial-gradient(circle, #616194 0%, #312a63 100%);
}

/* frontpage */
.section-content {
	padding-top: 1em;
	padding-bottom: 5em;
	text-align: justify;
	font-size: 1.2em;
	font-weight: 300;
	width: 100%;

	h6 {
		font-weight: 300;
		font-size: 1.4em;
		padding-bottom: 10px;
		color: @primaryColor;
	}
}

.ui.grid .rdm-goal.column {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.featured-list {
	background-color: #e55a5a;
}

/* Start Ulysseus Colors */
.up1 {
	color: @ulysseusprimary1;
}

.up2 {
	color: @ulysseusprimary2;
}

.up3 {
	color: @ulysseusprimary3;
}
/* End Ulysseus Colors */

.back-button-div {
	display: flex;
	width: 100%;

	.back-button-element {
		display: flex !important;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
	}
}

#profile-page {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 0 auto;
	justify-content: center;
	align-items: center;

	.user-profile-div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 25px;
		width: 100%;
		border: 2px solid @ulysseusprimary1;
		border-radius: 10px;
		gap: 50px;

		.section-presentation {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 50px;

			.picture {
				display: flex;
				justify-content: flex-end;

				.photo {
					background: @ulysseusprimary1;
					width: 200px;
					height: 200px;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 10px;

					p {
						font-size: 80px;
						color: white;
						margin: 0;
						line-height: 0;
					}
				}
			}

			.informations {
				display: flex;
				flex-direction: column;
				gap: 5px;

				.name {
					display: flex;
					flex-direction: row;
					gap: 50px;

					p {
						margin: 0;
					}
				}
			}
		}

		.section-informations {
			display: flex;
			flex-direction: column;

			p {
				margin: 0.3em 0em 1rem;
				font-weight: bold;
			}

			.links-content {
				display: flex;
				flex-direction: row;
				gap: 20px;

				.link-logo-parent {
					height: 40px;
					transition: transform 0.3s ease;

					.link-logo-child {
						height: 100%;
						width: auto;
						border-radius: 100px;
						cursor: pointer;
					}
				}
				.link-logo-parent:hover {
					transform: scale(1.05);
				}

				.name {
					display: flex;
					flex-direction: row;
					gap: 50px;
				}
			}
		}
	}
}
/*						------------*/
/*	END USER PROFILE PAGE			*/
/*						------------*/

/*--------------					*/
/*	START FRONTPAGE 				*/
/* preserve css in frontpage in v11	*/
/*--------------					*/

.rdm-goal .featured-list {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	height: 75px;
	width: 75px;
	margin: 0 auto;
	font-size: 30px;
	border-radius: 500px;
}

/*						------------*/
/*	END FRONTPAGE					*/
/*						------------*/

/*--------------					*/
/*	START PROFILE PAGE WIDTH 		*/
/* display record with beeter width	*/
/*--------------					*/

.ui.items {
	&.link > .item:hover .content .header {
		color: @darkTextColor;
	}

	.content {
		width: 100% !important;

		&.flex.right-column {
			flex-direction: column;
			align-items: end;
		}
	}
}

#records-list {
	width: 100%;

	.ui.container {
		width: 100%;
	}
	.ui ul {
		padding: 0px;
	}

	.ui.items {
		li.item {
			padding-left: 0 !important; // Needed because of override for li ~ .item
		}
	}
}

/*						------------*/
/*	END PROFILE PAGE WIDTH 			*/
/*						------------*/

.outer-navbar #frontpage-search-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/* Start Navigation tabs */
/***********************/

#invenio-dynamic-frontpage {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;

	.navigation-tabs-container {
		display: flex;
		justify-content: space-between;
		color: #fff;

		.tab {
			display: flex;
			flex-direction: column;
			flex: 1;
			align-items: center;
			border: none;
			padding: 15px 20px;
			cursor: pointer;
			color: #fff;
			font-size: 16px;
			background-color: white;
			transition: color 0.2s;
			color: #888;
			font-size: 18px;
			gap: 5px;

			.text {
				padding: 0;
				margin: 0;
			}

			.para {
				padding: 0;
				margin: 0;
				font-size: 22px;
			}

			.svg {
				font-size: 34px;
			}
		}

		.tab:first-child {
			box-shadow: inset -20px -20px 10px -20px #888;
		}

		.tab:last-child {
			box-shadow: inset 20px -20px 10px -20px #888;
		}

		.active {
			box-shadow: none !important;
			background-color: white;
			color: #e55a5a;
			border-top: 6px solid #e55a5a;
		}

		.tab:hover {
			color: #e55a5a;
		}
	}

	.navigation-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 20px;
		padding: 20px 0;

		.title {
			font-size: 22px;
			align-self: center;
			color: @ulysseusprimary2;
		}

		.institutions-content {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			align-items: end;
			justify-content: space-between;

			.institution {
				all: unset;
				display: flex;
				flex: 1;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				background: transparent;
				cursor: pointer;
				border: none;
				box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
				height: 200px;
				transition: 0.2s ease-out;
				padding: 10px 15px;
				border-radius: 2px;
				text-align: center;
				
				img {
					width: 200px;
				}

				p {
					font-size: 18px;
					font-weight: 400;
				}
			}

			.institution:hover {
				box-shadow: rgba(57, 51, 108, 0.40) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
			}
		}
	}
}

/* End Navigation tabs */
/***********************/
.rdm-goal .featured-list {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	height: 75px;
	width: 75px;
	margin: 0 auto;
	font-size: 30px;
	border-radius: 500px;
}

/* /////////////////////////////// */
/* 	 START RECENT POST INSTITUTION  */
/* /////////////////////////////// */
.recent-post-institution {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 25px;


	.search-origin-repository{
		display: flex;
    	justify-content: center;
	}

	.btn-back {
		width: fit-content;

		btn-back-text {
			font-size: 16px;
			margin: 0px;
			padding: 0px;
		}
	}

	.title {
		font-size: 28px;
		font-weight: 400;
	}

	.post {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;

		.card > * + * {
			margin-top: 1.1em;
		}

		.card {
			all: unset;
			--border-radius: 0.75rem;
			--primary-color: @gray;
			--secondary-color: #3c3852;
			width: 100%;
			font-family: "Arial";
			padding: 1rem;
			cursor: pointer;
			border-radius: var(--border-radius);
			border: 2px solid #c3c6ce;
			position: relative;
			transition: 0.2s ease-out;

			.card__content {
				color: var(--secondary-color);
				font-size: 0.86rem;
			}

			.card__title {
				padding: 0;
				font-size: 1.3rem;
				font-weight: bold;
			}

			.card__date {
				color: #6e6b80;
				font-size: 0.8rem;
			}

			.card__arrow {
				position: absolute;
				background: var(--primary-color);
				padding: 0.4rem;
				border-top-left-radius: var(--border-radius);
				border-bottom-right-radius: calc(var(--border-radius) - 0.1rem);
				bottom: 0;
				right: 0;
				transition: 0.2s;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			svg {
				transition: 0.2s;
			}
		}

		.card:hover {
			border-color: #172b69;
			box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.15);
			color: @main;

			.card__title {
				text-decoration: underline;
			}

			.card__arrow {
				background: @main;

				svg {
					transform: translateX(3px);
				}
			}
		}
	}
}

/* /////////////////////////////// */
/* 	 STOP RECENT POST INSTITUTIONS   */
/* /////////////////////////////// */

/* /////////////////////////////// */
/* 		START	LOADING 		   */
/* /////////////////////////////// */

#loading-frontpage {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;
	margin-top: 50px;

	.text {
		font-size: 20px;
	}

	.dot-spinner {
		--uib-size: 4rem;
		--uib-speed: 0.9s;
		--uib-color: #183153;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: var(--uib-size);
		width: var(--uib-size);
	}

	.dot-spinner__dot {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		width: 100%;
	}

	.dot-spinner__dot::before {
		content: "";
		height: 20%;
		width: 20%;
		border-radius: 50%;
		background-color: var(--uib-color);
		transform: scale(0);
		opacity: 0.5;
		animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
		box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
	}

	.dot-spinner__dot:nth-child(2) {
		transform: rotate(45deg);
	}

	.dot-spinner__dot:nth-child(2)::before {
		animation-delay: calc(var(--uib-speed) * -0.875);
	}

	.dot-spinner__dot:nth-child(3) {
		transform: rotate(90deg);
	}

	.dot-spinner__dot:nth-child(3)::before {
		animation-delay: calc(var(--uib-speed) * -0.75);
	}

	.dot-spinner__dot:nth-child(4) {
		transform: rotate(135deg);
	}

	.dot-spinner__dot:nth-child(4)::before {
		animation-delay: calc(var(--uib-speed) * -0.625);
	}

	.dot-spinner__dot:nth-child(5) {
		transform: rotate(180deg);
	}

	.dot-spinner__dot:nth-child(5)::before {
		animation-delay: calc(var(--uib-speed) * -0.5);
	}

	.dot-spinner__dot:nth-child(6) {
		transform: rotate(225deg);
	}

	.dot-spinner__dot:nth-child(6)::before {
		animation-delay: calc(var(--uib-speed) * -0.375);
	}

	.dot-spinner__dot:nth-child(7) {
		transform: rotate(270deg);
	}

	.dot-spinner__dot:nth-child(7)::before {
		animation-delay: calc(var(--uib-speed) * -0.25);
	}

	.dot-spinner__dot:nth-child(8) {
		transform: rotate(315deg);
	}

	.dot-spinner__dot:nth-child(8)::before {
		animation-delay: calc(var(--uib-speed) * -0.125);
	}

	@keyframes pulse0112 {
		0%,
		100% {
			transform: scale(0);
			opacity: 0.5;
		}

		50% {
			transform: scale(1);
			opacity: 1;
		}
	}
}

/* /////////////////////////////// */
/* 		END		LOADING 		   */
/* /////////////////////////////// */

/* /////////////////////////////// */
/* 	START	profile-institution 	   */
/* /////////////////////////////// */

#profile-institution {
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	padding: 10px 50px;
	border-radius: 2px;

	.text {
		text-align: center;
		font-size: 22px;
	}

	.container {
		display: flex;
		flex-direction: row;

		.section-left {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;

			.ancre {
				all: unset;
				display: flex;
				flex-direction: column;
			}

			img {
				width: 300px;
				border-radius: 25px;
			}
		}

		.section-right {
			display: flex;
			flex: 2;
			flex-direction: column;
			padding: 0 20px;

			.metadata-title {
				display: flex;
				justify-content: space-between;
			}

			.text {
				color: @ulysseusprimary2;
			}
		}
	}
}

/* /////////////////////////////// */
/* 	END		 profile-institution    */
/* /////////////////////////////// */

/* /////////////////////////////// */
/* 		START		 no-record     */
/* /////////////////////////////// */

#no-record {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	img {
		height: 150px;
	}

	p {
		padding: 0 30px;
		font-size: 20px;
		color: #888;
	}
}

/* /////////////////////////////// */
/* 		END			 no-record     */
/* /////////////////////////////// */

/* /////////////////////////////// */
/* 	 		START MEDIA QUERY  	   */
/* /////////////////////////////// */

@media (max-width: 1250px) {
	#profile-page {
		gap: 20px;
		margin: 0 20px;

		.user-profile-div {
			.section-presentation {
				.informations {
					gap: 5px;

					.name {
						flex-direction: column;
						gap: 5px;
					}
				}
			}
		}
	}

	.frontpage-dynamique {
		.render-component {
			padding: 0;
		}
	}
}

@media (max-width: 1000px) {
	#profile-page {
		gap: 20px;
		margin: 0 20px;
	}

	#profile-institution {
		.container {
			flex-direction: column;
			gap: 20px;
		}
	}
}

@media (max-width: 800px) {
	#profile-page {
		.user-profile-div {
			.section-presentation {
				flex-direction: column;
				gap: 20px;

				.picture {
					.photo {
						width: 150px;
						height: 150px;
						p {
							font-size: 60px;
						}
					}
				}

				.informations {
					min-width: 0px;
				}
			}
		}
	}
	.recent-post-institution {
		display: flex;
		flex-direction: column;
		margin: 0;
		width: 100%;
		gap: 25px;
		padding: 0;
	}
}

/* /////////////////////////////// */
/* 	 		END MEDIA QUERY  	   */
/* /////////////////////////////// */
